<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true" ref="form">
        <el-form-item label="交易时间" prop="username">
          <el-date-picker
            style="width: 380px"
            v-model="time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="设备编号" prop="tel">
          <el-input
            v-model="orderDeviceCode"
            clearable
            placeholder="请输入设备编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="成本价格 :" style="margin-left: 20px"
          >{{ cost_price }}元
        </el-form-item>
        <el-form-item label="合计金额 :" style="margin-left: 20px"
          >{{ price }}元
        </el-form-item>
        <el-form-item label="订单总数" style="margin-left: 40px"
          >{{ number }}
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" @click="search">搜索</el-button
          ><el-button type="primary" @click="addData" v-if="show == 2"
            >组合添加</el-button
          >
          <el-button type="primary" @click="editData" v-if="show == 2"
            >添加</el-button
          >

          <el-button @click="dao">导出</el-button>
          <el-button @click="xian" style="border: 0"></el-button>
        </el-form-item>
      </el-form>
    </div>
    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.productName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成本价" align="center">
        <template slot-scope="scope">
          <span v-if="show == 2">{{ scope.row.productCostPrice }}</span>
          <span v-if="show == 1"
            >{{
              (
                Number(scope.row.productCostPrice) +
                (Number(scope.row.orderMoneyPaid) * 5) / 100
              ).toFixed(2)
            }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="orderMoneyPaid" label="销售价" align="center">
      </el-table-column>
      <el-table-column prop="orderDeviceCode" label="设备编号" align="center">
      </el-table-column>
      <el-table-column prop="property" label="物业" align="center">
      </el-table-column>
      <el-table-column prop="orderCreateTime" label="交易时间" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center" width="350">
        <template slot-scope="scope">
          <el-link
            v-if="checkPermission('userdelete')"
            type="danger"
            style="margin-left: 10px"
            @click="deleteData(scope.row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </page-table>
    <!-- 新增编辑弹窗 -->
    <edit-data ref="editData" />
    <add-data ref="addData" />
  </div>
</template>

<script>
import { xunilist, unrealOrderDel } from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import pageTable from "@/components/pageTable.vue";
import { areaListData } from "@/utils/area";
import editData from "./components/editData.vue";
import addData from "./components/addData.vue";

export default {
  name: "user",
  components: {
    pageTable,
    editData,
    addData,
  },
  data() {
    return {
      userList: [], // 列表
      time: "",
      price: "",
      cost_price: "",
      number: "",
      show: 1,
      orderDeviceCode: "",
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  watch: {
    time(newVal) {
      if (newVal == null) {
        this.time = [];
      }
    },
  },
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    xian() {
      if (this.show == 1) {
        this.show = 2;
      } else {
        this.show = 1;
      }
    },
    editData() {
      this.$refs.editData.show();
    },
    addData() {
      this.$refs.addData.show();
    },
    dao() {
      if (this.time[1] == undefined) {
        window.location.href =
          this.$server.api +
          "/manybox/unrealOrderExp" +
          "?token=" +
          this.token +
          "&orderDeviceCode=" +
          this.orderDeviceCode;
      } else {
        window.location.href =
          this.$server.api +
          "/manybox/unrealOrderExp" +
          "?token=" +
          this.token +
          "&orderDeviceCode=" +
          this.orderDeviceCode +
          "&time1=" +
          this.time[0] +
          "&time2=" +
          this.time[1];
      }
    },
    deleteData(row) {
      this.$confirm("是否删除此信息？", "提示", {
        type: "warning",
      })
        .then(async () => {
          let params = {
            id: row.id,
            token: sessionStorage.getItem("token"),
          };
          unrealOrderDel(params).then((res) => {
            if (res.status == 200) {
              this.getUserList();
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.data.msg);
              this.getUserList();
            }
          });
        })
        .catch(() => {});
    },
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    search() {
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {
        page: 1,
        token: sessionStorage.getItem("token"),
        limit: this.page.pageSize,
        orderDeviceCode: this.orderDeviceCode,
        time1: this.time[0],
        time2: this.time[1],
      };
      xunilist(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.number = res.data.info.number;
        this.price = res.data.info.price;
        this.cost_price = res.data.info.cost_price;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {
        token: sessionStorage.getItem("token"),
        page: this.page.currentPage,
        limit: this.page.pageSize,
        orderDeviceCode: this.orderDeviceCode,
        time1: this.time[0],
        time2: this.time[1],
      };
      xunilist(params).then((res) => {
        this.page.total = res.data.count;
        this.userList = res.data.data;
        this.number = res.data.info.number;
        this.price = res.data.info.price;
        this.cost_price = res.data.info.cost_price;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
